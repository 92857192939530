
import { React, Page } from 'lib'
import FaqPage from './_faq'

function FAQPage(props) {
  return (
    <Page whiteBackground>
      <FaqPage/>
    </Page>
  )
}

export default FAQPage
