import { React, View, SEO, CenterWrapper, StyleSheet } from 'lib'
import { Theme } from 'app'
import FaqItem from './FaqItem'
import data from './_data'
import { PageTitle } from 'components'

const header = 'Hey I will be your guide today'

function Faq(props) {
  return (
    <View style={styles.wrapper}>
      <SEO doNotTrack title={'FAQ'} description={header}/>
      <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.content}>
        <PageTitle location='FAQ'/>

        {data.map((item, key) => {
          return (
            <FaqItem
              key={key}
              {...item}
            />
          )
        })}

      </CenterWrapper>
    </View>
  )
}

export default Faq

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    ...Theme.flex,
    flexDirection: 'column',
    marginTop: Theme.spacing(10),
    marginBottom: Theme.spacing(5),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(6),
      marginBottom: Theme.spacing(5),
    },
  },
  content: {
    // maxWidth: Theme.values.maxTextContentWidth,
    flexDirection: 'column',
    gap: Theme.spacing(5),
  },
  title: {
    width: 'fit-content',
    backgroundImage: Theme.colors.gradientLinear,
    backgroundClip: 'text',
    color: 'transparent',
  },
})
