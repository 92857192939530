
export interface FAQItemProps {
  id: number;
  title: string;
  content: string[]
  link?: {
    title: string;
    url: string;
  }
}

type items = FAQItemProps[]

const data: items = [
  {
    id: 1,
    title: 'What is Becon?',
    content: [
      'Becon is the smart safety app that protects your walks, runs, cycles & more, all with the tap of a button. You simply activate Becon ahead of your journey or activity, and the app will monitor your device until you finish. If anything out of the ordinary is detected for your chosen mode of travel then Becon will check in on you with a timed and secure notification. If no response is received by the end of the timer, then your Key Contacts will be alerted automatically.',
    ],
  },
  {
    id: 2,
    title: 'What do the various buttons on the Becon map and home screen do?',
    content: [
      'For a brief tour of your app and map screen, check out this video:',
    ],
    link: {
      title: 'Map & Homescreen Tour',
      url: '/how-to-use/map-homescren-tour',
    },
  },
  {
    id: 3,
    title: 'How does Becon know if I am incapacitated or need assistance?',
    content: [
      'When you activate Becon, the app will monitor the speed, motion and location of your device until you reach your destination, or you turn it off. If Becon detects activity beyond the safety parameters that are set, it will check in on you with a timed and secure notification. If you are unable to respond to this notification by the end of the timer, Becon assumes you are incapacitated, unconscious or separated from your device and therefore in need of assistance.',
    ],
  },
  {
    id: 4,
    title: 'Will Becon alert my contacts even if I set it off accidentally?',
    content: [
      'Becon’s technology is very clever, however it won’t be able to tell if you have set off a Safety Trigger accidentally, or if you are truly in need of assistance - and for your safety, it must presume any detected issues to be real. Remember though, Becon will always check in on you before sending alerts to your Key Contacts, so you will have the duration of the countdown to cancel the timer and dismiss the alert if you are actually safe. Becon+ users can also change the duration of this check-in countdown too, so you can always adjust it if you find yourself needing more time to respond.',
    ],
  },
  {
    id: 5,
    title: 'How do I activate Becon for a journey?',
    content: [
      'For a brief step-by-step guide on how to activate Becon for a journey, check out this video:',
    ],
    link: {
      title: 'Activate Becon For A Journey',
      url: '/how-to-use/activate-becon-for-a-journey',
    },
  },
  {
    id: 6,
    title: 'Does Becon contact the emergency services when alerts are sent?',
    content: [
      'No. At Becon, we appreciate that not all situations where you need help will require the emergency services, and that it can also be off-putting to use an app like ours in certain situations if you think it could automatically alert the emergency services when you may not need it to. If you are unresponsive and Becon needs to send alerts, they go instantly to your chosen contacts only. In these scenarios, your Key Contacts will immediately receive information about your planned journey, your actual travelled route, and your most up to date location. They can then use that information to contact or assist you, and if they think it is necessary they may decide to call the emergency services, which they can do directly from the location link provided to them.',
    ],
  },
  {
    id: 7,
    title: 'How do I add a new Key Contact?',
    content: [
      'You can manage your Key Contacts via the tab in the app menu. Check out this quick video to see how:',
    ],
    link: {
      title: 'How To Add A Key Contact',
      url: '/how-to-use/how-to-add-a-key-contact',
    },
  },
  {
    id: 8,
    title: 'Will my Key Contacts be notified when I use Becon for a journey?',
    content: [
      'Becon is built with the privacy and independence of our user’s at the core and so, unlike other apps, does not require you to notify your Key Contacts when you activate it for a journey or when you arrive at your destination. They are only notified if you choose to do so manually, or if an automated Safety Trigger is activated and alerts are sent because you are unresponsive. We would always encourage you to let your loved ones, friends or colleagues know about your journeys for added safety whenever possible but that choice is up to you, rather than a requirement.',
    ],
  },
  {
    id: 9,
    title: 'Can my Key Contacts see my location?',
    content: [
      'Becon is built with the privacy and independence of our user’s at the core, and so unlike other apps, it does not require you to share your location with anyone when you activate it for a journey. Your Key Contacts are only notified if you choose to do so manually, or if an automated Safety Trigger is activated and alerts are sent because you are unresponsive. We would always encourage you to share your journeys or location with loved ones, friends or colleagues for added safety whenever possible but that choice is up to you, rather than a requirement.',
      'As a Becon+ subscriber you can share your live journey with Key Contacts directly from within the app.',
    ],
  },
  {
    id: 10,
    title: 'Can I use Becon abroad?',
    content: [
      'Becon can be used anywhere in the world as long as the user has a mobile data connection (3G/4G/5G).',
    ],
  },
  {
    id: 11,
    title: 'Can I use Becon without mobile signal?',
    content: [
      'To make sure you are safe during a journey or activity, Becon needs to maintain a stable connection to your device while it is activated, and it does so via mobile data signal (3G/4G/5G). A bit of patchy mobile signal during your journey or activity may mean your device disconnects for short periods of time but don’t worry, this won’t cause alerts to be sent. Your device can be disconnected for up to 45mins before Becon’s ‘Disconnected’ Safety Trigger will be activated and alerts sent to your Key Contacts. The ‘Disconnected’ Safety Trigger protects you against the unwanted deactivation of the app or damage to/destruction of your device during your journey or activity.',
    ],
  },
  {
    id: 12,
    title: 'Do I have to turn off Becon when I reach my destination?',
    content: [
      'When using Becon to travel a route to a searched destination or Saved Place, the app will turn off automatically when you arrive. For Becon+ users, when using the ‘Just Go’ mode you will need to deactivate the app manually once you have finished your journey or activity.',
    ],
  },
  {
    id: 13,
    title: 'What if I want to travel a different route to the one displayed by the app?',
    content: [
      'At Becon we understand that you may want to take a different route to the ones provided by the app so we have made it quick and easy to reroute if you want to go a different way.',
      'If you deviate by more than the set distance (the default is 50m), then the ‘Off Route’ Safety Trigger will be activated, and a timed notification will pop up on your screen. When that happens, all you need to do to carry on your journey is tap ‘I am safe’, then ‘Reroute’, and Becon will automatically recalculate a new route from your current location.',
      'You may want to travel without any route at all – when exploring a new city on foot, walking the dog, or cycling around the park for example. In that case you may wish to use Becon’s ‘Just Go’ mode. ‘Just Go’ is a premium feature available to Becon+ subscribers and activates all Safety Triggers to look out for you except for ‘Off Route’, so you can carry out your activity without the need to keep to a specific route or enter a destination.',
    ],
  },
  {
    id: 14,
    title: 'Does Becon work for running and cycling journeys, or when I need to take other modes of transport?',
    content: [
      'Our free users can use Becon to protect their walking journeys only, however if you are a Becon+ subscriber you can create and save modes for different activities like running and cycling. You can also adjust and set your alert parameters to suit your personal preference and activity style.',
    ],
  },
  {
    id: 15,
    title: 'Why does Becon need access to my device’s location ‘all the time’?',
    content: [
      'As a smart safety app with navigation features, Becon needs to be able to track your device’s location for the duration of your journey or activity, whether the app is open on your device or minimised in the background. Becon does not actively track your location unless you activate the app for a journey. This access to your device’s location is essential to keeping you safe and providing your Key Contacts with your location in the event of an emergency; location data is kept completely private and never used for any other purpose.',
    ],
  },
  {
    id: 16,
    title: 'What do I get with Becon’s free plan?',
    content: [
      'As a free user you get all the power of Becon’s automated safety technology to look out for you, however the app is restricted to protecting walking journeys only.',
      'You can use Becon to walk to any destination you wish all around the world, assign favourite locations to your home screen buttons and add up to 5 Saved Places. Our free users can also add up to 10 Key Contacts.',
      'Although Becon’s safety settings are fixed to protect your walking journeys only, you still have the full automated protection that Becon offers when making those journeys from A to B.',
    ],
  },
  {
    id: 17,
    title: 'How do I set up my ‘Home’ and ‘Favourite’ buttons on the home-screen and store ‘Saved Places’?',
    content: [
      'To assign a location to your Home button you simply need to search for your desired address and then tap the star symbol on right of the tab that appears. Tap the ‘Home’ option in the pop up window to assign it. The destination will now be set as your Home button.',
      'To add Saved Places, you follow the same process except when the pop up appears you need to select ‘+ Saved Place’ instead. Your Saved Places will appear on your map as symbols you can tap on and also in a list horizontally underneath the search bar when you are searching for destinations.',
      'To assign your Favourite button on your home screen, simply go to the Saved Places tab in the app menu. Select a Saved Place from your list and tap on it. Tap to set the location as your Favourite button when prompted to do so.',
      'To see how to set up your ‘Home’ and ‘Favourite’ buttons on the home-screen and store Saved Places, watch this video tutorial:',
    ],
    link: {
      title: 'How To Add Saved Places And Assign Home Screen Buttons',
      url: '/how-to-use/how-to-add-saved-places-and-assign-home-screen-buttons',
    },
  },
  {
    id: 18,
    title: 'What do I get with the Becon+ plan?',
    content: [
      'Becon+ users unlock a host of features and functions that give you the power to use Becon on any activity you want, from running to cycling, horse-riding to skiing, and everything in between. You will also get full control over Becon’s safety settings, meaning you can easily adjust the app’s sensitivity to suit you personally.',
      'Becon+ users are also able to assign individual ‘Journey Contacts’ who are contacts you want to receive Becon’s emergency alerts, but only when you are travelling to specific destinations - perfect for when you are travelling abroad or if you want to add work contacts for your journeys to and from the office, amongst other things.',
      'You can also store unlimited Saved Places and up to 50 Key Contacts, not to mention share your live journeys with your contacts for added peace-of-mind if and when you wish to.',
    ],
  },
  {
    id: 19,
    title: 'What is the ‘Just Go’ mode?',
    content: [
      'By tapping the middle button on the home screen and entering ‘Just Go’ mode, you will activate all of Becon’s Safety Triggers to look out for, you except for ‘Off Route’. This means you can go wherever you like, without the need to keep to a specific route or enter a destination.',
      'This mode is great for meandering strolls, spontaneous cycles, dog walks or any kind of unplanned journey or activity - just remember to turn Becon off when you are finished.',
      'To see how to activate ‘Just Go’ take a look at this video:',
    ],
    link: {
      title: 'Using Just Go Mode',
      url: '/how-to-use/using-just-go-mode',
    },
  },
  {
    id: 20,
    title: 'What are Becon’s safety settings and how do I change them?',
    content: [
      'Becon has four automated Safety Triggers that keep you safe during your journey or activity; Off Route, Stopped Moving, High Speed and Disconnected. When activated, Becon monitors the speed, motion and location of your device until you reach your destination or manually turn it off. If activity is detected that breaches any of the parameters set for these Safety Triggers, then a timed notification will appear on your device checking you are ok. If you are unable to respond to confirm your safety by the end of the timer then alerts will automatically be sent to your Key Contacts. As a Becon+ user, you are able to adjust and enable/disable any of the app’s Safety Triggers, as well as the length of the countdown timer. You also have the power to create your own custom safety modes for any type of journey or activity you want to protect.',
      'Check out this video tutorial on how to create and edit new safety modes via the My Safety tab in the app menu:',
    ],
    link: {
      title: 'How to create & edit safety modes',
      url: '/how-to-use/how-to-create-&-edit-safety-modes',
    },
  },
  {
    id: 21,
    title: 'What is the difference between Journey Contacts and Key Contacts?',
    content: [
      'Your Key Contacts are the people Becon will send alerts to in the case of emergency, no matter where you are going. As a Becon+ user, you can also add Journey Contacts. Journey Contacts can be assigned to a specific Saved Place or activated for individual journeys, and they will only be alerted if an emergency occurs when you’re travelling on that specific journey or to that Saved Place. For example, you may not want your colleague to be your Key Contact, but you do want them to receive alerts if an emergency were to occur when you are travelling to your place of work, which you have stored as a Saved Place. With Becon+ you can assign your colleague as a Journey Contact to your work address, so that if something were to occur whenever you are travelling to your work address they would also be alerted, in addition to your Key Contacts.',
    ],
  },
]

export default data
