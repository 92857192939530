import { motion } from 'types/customMotion'
import { React, View, Text, StyleSheet, useState, Touchable, Link } from 'lib'
import { Theme } from 'app'
import { ArrowRightIcon } from '../../assets/icons'
import { FAQItemProps } from './_data'

const renderParagraph = (txt, idx=0) => {
  return <Text key={idx} variant={'p3 marginTop:2'}>{txt}</Text>
}

const FAQItem: React.FC<FAQItemProps> = ({ id, title, content, link }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <View>
      <Touchable variant={'column'} onPress={() => setExpanded(!expanded)}>
        <View variant={'row alignCenter'} style={styles.summary}>
          <motion.View
            variants={arrowVariants}
            animate={expanded ? 'rotate' : 'init'}
            transition={{ bounce: 0.3 }}
          >
            <ArrowRightIcon size={30}/>
          </motion.View>
          <Text variant={'h4'} text={title}/>
        </View>
        <motion.View
          variants={expandingSectionVariants}
          animate={expanded ? 'show' : 'init'}
          transition={{ bounce: 0.3 }}
          style={styles.expandingSection}
        >
          {content?.length === 1 ? renderParagraph(content[0]) : content?.map((i, k) => renderParagraph(i, k))}
        </motion.View>
      </Touchable>
      {link && (
        <motion.View
          variants={expandingSectionVariants}
          animate={expanded ? 'show' : 'init'}
          transition={{ bounce: 0.3 }}
          style={styles.expandingSection}
        >
          <Link to={link.url} openNewTab>
            <Text variant={'p3 bold'} style={styles.link}>{link.title}</Text>
          </Link>
        </motion.View>
      )}
      <View style={styles.separator}/>
    </View>
  )
}

export default FAQItem

const arrowVariants = {
  init: {
    rotate: 0,
    duration: 50,
  },
  rotate: {
    rotate: 90,
    duration: 50,

  },
}

const expandingSectionVariants = {
  init: {
    height: 0,
    duration: 125,
  },
  show: {
    height: 'auto',
    duration: 150,
  },
}

const styles = StyleSheet.createComponentStyleSheet({
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: '#777',
    marginTop: Theme.spacing(3),
  },
  link: {
    color: Theme.colors.grad1,
    background: Theme.colors.gradientLinear,
    textDecorationColor: Theme.colors.gradientLinear,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'inline-block',
  },
  summary: {
    [Theme.media.up('small')]: {
      gap: Theme.spacing(5),
    },
    [Theme.media.down('small')]: {
      gap: Theme.spacing(3),
    },
  },
  expandingSection: {
    overflow: 'hidden',
    height: 0,
    paddingTop: Theme.spacing(2),
  },
})
